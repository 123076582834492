//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LottieAnimation from 'lottie-web-vue' // import lottie-web-vue
export default {
  components: {
    LottieAnimation,
  },
}
