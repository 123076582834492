//
//
//
//
//
//

export default {
  name: 'Icon',
  props: {
    name: {
      type: String,
      default: '',
    },
  },
  computed: {
    src() {
      return this.name ? require(`static/icons/${this.name}.svg?raw`) : ''
    },
  },
}
