//
//
//
//
//
//
//
//
//
//

import Error from '../components/Error.vue'

export default {
  components: {
    Error,
  },
  layout: 'error',
  props: {
    error: {
      type: Object,
      default: () => {},
    },
  },
}
