//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Recommended from './Recommended.vue'
import MyFollowers from './MyFollowers.vue'

export default {
  components: {
    Recommended,
    MyFollowers,
  },
  data() {
    return {
      step: 1,
      searchFollowers: '',
      searchCompanies: '',
    }
  },
  fetch() {
    if (this.$refs.rec1) {
      this.$refs.rec1?.fetchData()
    }
    if (this.$refs.rec2) {
      this.$refs.rec2?.fetchData()
    }
  },
  computed: {
    searchModel() {
      if (this.step === 1) {
        return 'searchFollowers'
      } else {
        return 'searchCompanies'
      }
    },
  },
  watch: {
    '$route.fullPath'() {
      this.$modalHide('subsTab')
      this.searchFollowers = ''
      this.searchCompanies = ''
      this.step = 1
    },
    step() {
      this.$fetch()
    },
  },

  methods: {
    nextStep(action = '') {
      switch (this.step) {
        case 1:
          this.step1(action)
          break
        default:
          break
      }
    },
    prevStep() {
      if (this.step > 1) {
        this.step--
      }
    },
    step1(action) {
      if (action.includes('next')) return this.step++
    },
  },
}
