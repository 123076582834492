export const state = () => ({
  eventsListLoading: false,
  filterResult: undefined,
  events: [],
  eventsHelpersAbout: [],
  eventsHelpersSpeakers: [],
  eventsList: {
    items: [],
    total: 0,
  },
  eventsTotal: 0,
  eventsPage: 0,
  single: undefined,
  interest: {},
  autherEvents: {
    items: [],
    total: 0,
    page: 0,
  },
  reletedEvents: {
    items: [],
    total: 0,
    page: 0,
  },
  category: [],
  tariffs: [],
})

export const mutations = {
  setEvents(state, value) {
    state.events = value
  },
  setEventsHelpersAbout(state, value) {
    state.eventsHelpersAbout = value
  },
  setEventsHelpersSpeakers(state, value) {
    state.eventsHelpersSpeakers = value
  },
  SET_FILTER_RESULT(state, value) {
    state.filterResult = value
  },
  setEventsListLoading(state, value) {
    state.eventsListLoading = value
  },
  SET_EVENTS_LIST(state, events) {
    state.eventsList.items = events.results
    state.eventsList.total = events.count
  },
  PUSH_EVENTS_LIST(state, events) {
    state.eventsList.items.push(...events.results)
  },
  SET_SINGLE(state, single) {
    state.single = single
  },
  SET_AUTHER_EVENTS(state, events) {
    state.autherEvents.items = events.results
    state.autherEvents.total = events.total
    state.autherEvents.page = events.current_page
  },
  PUSH_AUTHER_EVENTS(state, events) {
    state.autherEvents.items.push(...events.results)
    state.autherEvents.page = events.current_page
  },
  SET_RELETED_EVENTS(state, events) {
    state.reletedEvents.items = events.results
    state.reletedEvents.total = events.total
    state.reletedEvents.page = events.current_page
  },
  PUSH_RELETED_EVENTS(state, events) {
    state.reletedEvents.items.push(...events.results)
    state.reletedEvents.page = events.current_page
  },
  setCategory(state, category) {
    state.category = category
  },
  SET_EVENT_TARIFFS(state, tariffs) {
    state.tariffs = tariffs
  },
}

export const actions = {
  async fetchEventsList({ commit }, filters) {
    if (filters.page === 1) commit('setEventsListLoading', true)
    return await new Promise((resolve, reject) => {
      let params
      if (filters) {
        params = {
          title__contains: filters.search || undefined,
          status: filters.status || undefined,
          category_id__terms: filters.categories
            ? filters.categories.join('__')
            : undefined,
          is_free: filters.isFree || undefined,
          type: filters.online || undefined,
          start_date: filters.selectedDate || undefined,
          page: filters.page || 1,
          page_size: filters.size || undefined,
        }
      }
      this.$axios
        .get('event/', {
          headers: { 'Accept-Language': this.$i18n.locale },
          params,
        })
        .then((res) => {
          if (filters.filtering)
            return commit('SET_FILTER_RESULT', res.data.count)
          if (filters.page > 1) return commit('PUSH_EVENTS_LIST', res.data)
          commit('SET_EVENTS_LIST', res.data)
          resolve(res)
        })
        .catch((error) => {
          reject(error) // reject
        })
        .finally(() => {
          commit('setEventsListLoading', false)
        })
    })
  },
  async fetchEventSingle({ commit }, { slug }) {
    return await new Promise((resolve, reject) => {
      this.$axios
        .get(`/event/detail/${slug}/`, {
          headers: { 'Accept-language': this.$i18n.locale },
        })
        .then((res) => {
          commit('SET_SINGLE', res.data)
          resolve(res)
        })
        .catch((error) => {
          reject(error) // reject
        })
    })
  },
  async addParticipant({ commit }, slug) {
    return await new Promise((resolve, reject) => {
      this.$axios
        .post(`/event/${slug}/add-participant/`, {
          headers: { 'Accept-language': this.$i18n.locale },
        })
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error) // reject
        })
    })
  },
  async removeParticipant({ commit }, slug) {
    return await new Promise((resolve, reject) => {
      this.$axios
        .post(`/event/${slug}/remove-participant/`, {
          headers: { 'Accept-language': this.$i18n.locale },
        })
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error) // reject
        })
    })
  },
  async fetchAuthorEvents({ commit }, { slug, page = 1, size = 3 }) {
    return await new Promise((resolve, reject) => {
      this.$axios
        .get(`/event/author/related/${slug}/`, {
          headers: { 'Accept-language': this.$i18n.locale },
          params: { page, size },
        })
        .then((res) => {
          commit('PUSH_AUTHER_EVENTS', res.data)
          page > 1
            ? commit('PUSH_AUTHER_EVENTS', res.data)
            : commit('SET_AUTHER_EVENTS', res.data)
          resolve(res)
        })
        .catch((error) => {
          reject(error) // reject
        })
    })
  },
  async fetchReletedEvents({ commit }, { slug, page = 1, size = 3 }) {
    return await new Promise((resolve, reject) => {
      this.$axios
        .get(`/event/related/${slug}/`, {
          headers: { 'Accept-language': this.$i18n.locale },
          params: { page, size },
        })
        .then((res) => {
          page > 1
            ? commit('PUSH_RELETED_EVENTS', res.data)
            : commit('SET_RELETED_EVENTS', res.data)
          resolve(res)
        })
        .catch((error) => {
          reject(error) // reject
        })
    })
  },
  async fetchCategory({ commit }) {
    return await new Promise((resolve, reject) => {
      this.$axios
        .get('event/category/', {
          headers: { 'Accept-Language': this.$i18n.locale },
        })
        .then((res) => {
          // console.log(res.data);
          commit('setCategory', res.data)
          resolve(res)
        })
        .catch((error) => {
          reject(error) // reject
        })
    })
  },
  async fetchEventTariffTypes({ commit }) {
    return await new Promise((resolve, reject) => {
      this.$axios
        .get('payment/events/tariffs/', {
          headers: { 'Accept-Language': this.$i18n.locale },
        })
        .then((res) => {
          // console.log(res.data);
          commit('SET_EVENT_TARIFFS', res.data)
          resolve(res)
        })
        .catch((error) => {
          reject(error) // reject
        })
    })
  },
}

export const getters = {
  eventsList: (s) => s.eventsList,
  autherEvents: (s) => s.autherEvents,
  reletedEvents: (s) => s.reletedEvents,
}
