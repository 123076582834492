import Vue from 'vue'
export default function ({ $axios, store, redirect, error }) {
  $axios.onError((error) => {
    if (error?.response?.config?.url === '/article/crud/add/') {
      return false
    } else if (error?.response?.status === 400) {
      Vue.prototype.$validationError(error.response.data)
    }
  })
}
