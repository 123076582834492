//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      canShow: false,
    }
  },
  beforeMount() {
    window.addEventListener('scroll', this.sidebarUpScroll)
    window.addEventListener('click', (e) => {})
  },
  methods: {
    sidebarUpScroll() {
      const y = window.scrollY
      if (y >= 400) {
        this.canShow = true
      } else {
        this.canShow = false
      }
    },
    scrollUp() {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    },
  },
}
