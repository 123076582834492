//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    img: {
      type: String,
      default: '',
    },
    contentC: {
      type: String,
      default: '',
    },
    closeC: {
      type: String,
      default: '',
    },
    lightbox: {
      type: Boolean,
      default: false,
    },
    white: {
      type: Boolean,
      default: false,
    },
    headC: {
      type: String,
      default: '',
    },
    icon: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    width: {
      type: Number,
      default: 640,
    },
    height: {
      type: Number,
      default: null,
    },
    id: {
      type: String,
      default: '',
    },
    showHead: {
      type: Boolean,
      default: true,
    },
    bodyC: {
      type: String,
      default: 'p-[20px]',
    },
    warning: {
      type: Boolean,
      default: false,
    },
    closable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modalIsOpen: false,
    }
  },
  watch: {
    modalIsOpen(item) {
      this.$emit('position', item)
    },
    '$route.fullPath'(newValue, oldValue) {
      if (
        document &&
        document.documentElement &&
        document.documentElement.classList.contains('_lock')
      ) {
        document.documentElement.classList.remove('_lock')
      }
    },
  },
}
