export const state = () => ({
  savedList: [
    {
      title: 'Yoqtirganlarim',
      id: 1,
      count: 0,
      vacancies: [
        {
          title: 'SMM menedjer',
          company: 'Server Service Plus',
          salary_from: '8 000 000',
          salary_to: '10 000 000',
          currency: 1,
          slug: 'smm-menedjer',
        },
        {
          title: 'Video Montajyor',
          company: 'Server Service Plus',
          salary_from: '8 000 000',
          salary_to: '10 000 000',
          currency: 1,
          slug: 'smm-menedjer',
        },
      ],
    },
    {
      title: 'Sayohat',
      id: 2,
      count: 5,
      vacancies: [
        {
          title: 'TESTER',
          company: 'EPAM',
          salary_from: '8 000 000',
          salary_to: '10 000 000',
          currency: 1,
          slug: 'smm-menedjer',
        },
        {
          title: 'SMM menedjer',
          company: 'Server Service Plus',
          salary_from: '8 000 000',
          salary_to: '10 000 000',
          currency: 1,
          slug: 'smm-menedjer',
        },
        {
          title: 'SMM menedjer',
          company: 'Server Service Plus',
          salary_from: '8 000 000',
          salary_to: '10 000 000',
          currency: 1,
          slug: 'smm-menedjer',
        },
      ],
    },
    {
      title: "O'qish",
      id: 3,
      count: 10,
      vacancies: [
        {
          title: 'iOS Swift Developer',
          company: 'Server Service Plus',
          salary_from: '8 000 000',
          salary_to: '10 000 000',
          currency: 1,
          slug: 'smm-menedjer',
        },
        {
          title: 'UI/UX Designer',
          company: 'Server Service Plus',
          salary_from: '8 000 000',
          salary_to: '10 000 000',
          currency: 1,
          slug: 'smm-menedjer',
        },
        {
          title: 'UI/UX Designer',
          company: 'Server Service Plus',
          salary_from: '8 000 000',
          salary_to: '10 000 000',
          currency: 1,
          slug: 'smm-menedjer',
        },
        {
          title: 'SMM menedjer',
          company: 'Server Service Plus',
          salary_from: '8 000 000',
          salary_to: '10 000 000',
          currency: 1,
          slug: 'smm-menedjer',
        },
      ],
      comments: [
        {
          id: 1,
          picture:
            'https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50?s=200',
          fullname: 'John Doe',
          when: '2020/04/12 12:00',
          comment: 'Sen hozir o‘ylab gapirdingmi?',
          comment_count: 120,
          is_verified: true,
          reply: [
            {
              id: 2,
              picture:
                'https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50?s=200',
              fullname: 'Tim Cook',
              when: '2021/04/12 12:10',
              comment: '🤣 🤣 🤣 🐉',
              comment_count: 120,
              is_verified: true,
              reply: [
                {
                  id: 3,
                  picture:
                    'https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50?s=200',
                  fullname: 'Elon',
                  when: '2021/04/12 12:40',
                  comment: 'Nimalar diyurasiz-e daun',
                  comment_count: 21,
                  reply: [],
                  is_verified: false,
                },
                {
                  id: 5,
                  picture:
                    'https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50?s=200',
                  fullname: 'Elon',
                  when: '2021/04/12 12:40',
                  comment: '<script> alert(`you have been hacked`)</script>',
                  comment_count: 21,
                  reply: [],
                  is_verified: false,
                },
                {
                  id: 6,
                  picture:
                    'https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50?s=200',
                  fullname: 'Elon',
                  when: '2021/04/12 12:40',
                  comment: '<a href=`google.com`>Google</a>',
                  comment_count: 21,
                  reply: [],
                  is_verified: false,
                },
              ],
            },
          ],
        },
        {
          id: 4,
          picture:
            'https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50?s=200',
          fullname: 'Elon',
          when: '2021/04/12 12:40',
          comment: 'Nimalar diyurasiz-e daun',
          comment_count: 21,
          is_verified: false,
          reply: [],
        },
      ],
    },
  ],
  profile: {},
  profileArticles: [],
  total_pages: 1,
  profileVacancy: [],
  profileEvents: [],
  profileComments: [],
  profileSubs: [],
  profileSaved: [],
  profileSavedSingle: [],
  profileQr: undefined,
  notes_list: [],
  userBlogs: [],
  blogCategory: [],
  blackList: [],
})
export const mutations = {
  SET_PROFILE(state, profile) {
    state.profile = profile
  },
  SET_PROFILE_ARTICLES(state, profileArticles) {
    state.profileArticles = profileArticles
  },
  SET_TOTAL_PAGES(state, total) {
    state.total_pages = total
  },
  SET_PROFILE_VACANCY(state, profileVacancy) {
    state.profileVacancy = profileVacancy
  },
  SET_PROFILE_EVENTS(state, profileEvents) {
    state.profileEvents = profileEvents
  },
  SET_PROFILE_COMMENT(state, profileComments) {
    state.profileComments = profileComments
  },
  SET_PROFILE_SUBS(state, profileSubs) {
    state.profileSubs = profileSubs
  },
  SET_PROFILE_SAVED(state, saved) {
    state.profileSaved = saved
  },
  SET_PROFILE_SAVED_SINGLE(state, single) {
    state.profileSavedSingle = single
  },
  SET_PROFILE_QR(state, qr) {
    state.profileQr = qr
  },
  SET_NOTIFICATION_LIST(state, notes) {
    state.notes_list = notes
  },
  SET_BLOG_LISTS(state, lists) {
    state.userBlogs = lists
  },
  SET_BLOG_CATEGORY(state, category) {
    state.blogCategory = category
  },
  SET_BLACK_LISTS(state, black) {
    state.blackList = black
  },
}
export const actions = {
  async fetchProfile({ commit }) {
    return await new Promise((resolve, reject) => {
      this.$axios
        .get('author/profile/', {
          headers: { 'Accept-Language': this.$i18n.locale },
        })
        .then((res) => {
          commit('SET_PROFILE', res.data)
          resolve(res)
        })
        .catch((error) => {
          reject(error) // reject
        })
    })
  },
  async fetchProfileArticles({ commit }, { size, page }) {
    return await new Promise((resolve, reject) => {
      this.$axios
        .get(`author/profile/articles/?size=${size}&page=${page}`, {
          headers: { 'Accept-Language': this.$i18n.locale },
        })
        .then((res) => {
          commit('SET_PROFILE_ARTICLES', res.data.results)
          commit('SET_TOTAL_PAGES', res.data.total_pages)
          resolve(res)
        })
        .catch((error) => {
          reject(error) // reject
        })
    })
  },
  async fetchProfileVacancy({ commit }, { username, size, page }) {
    return await new Promise((resolve, reject) => {
      this.$axios
        .get(`author/profile/vacancy?size=${size}&page=${page}`, {
          headers: { 'Accept-Language': this.$i18n.locale },
        })
        .then((res) => {
          commit('SET_PROFILE_VACANCY', res.data.results)
          commit('SET_TOTAL_PAGES', res.data.total_pages)
          resolve(res)
        })
        .catch((error) => {
          reject(error) // reject
        })
    })
  },
  async fetchProfileEvents({ commit }, { username, size, page }) {
    return await new Promise((resolve, reject) => {
      this.$axios
        .get(`author/profile/event?size=${size}&page=${page}`, {
          headers: { 'Accept-Language': this.$i18n.locale },
        })
        .then((res) => {
          commit('SET_PROFILE_EVENTS', res.data.results)
          commit('SET_TOTAL_PAGES', res.data.total_pages)
          resolve(res)
        })
        .catch((error) => {
          reject(error) // reject
        })
    })
  },
  async fetchProfileComments({ commit }, { username, size, page }) {
    return await new Promise((resolve, reject) => {
      this.$axios
        .get(`author/profile/comments/?size=${size}&page=${page}`, {
          headers: { 'Accept-Language': this.$i18n.locale },
        })
        .then((res) => {
          commit('SET_PROFILE_COMMENTS', res.data.results)
          commit('SET_TOTAL_PAGES', res.data.total_pages)
          resolve(res)
        })
        .catch((error) => {
          reject(error) // reject
        })
    })
  },
  async fetchProfileSubs({ commit }) {
    return await new Promise((resolve, reject) => {
      this.$axios
        .get('author/profile/subscribers/', {
          headers: { 'Accept-Language': this.$i18n.locale },
        })
        .then((res) => {
          commit('SET_PROFILE_SUBS', res.data.results)
          commit('SET_TOTAL_PAGES', res.data.total_pages)
          resolve(res)
        })
        .catch((error) => {
          reject(error) // reject
        })
    })
  },
  async fetchProfileSaved({ commit }) {
    return await new Promise((resolve, reject) => {
      this.$axios
        .get('author/saved/', {
          headers: { 'Accept-Language': this.$i18n.locale },
        })
        .then((res) => {
          commit('SET_PROFILE_SAVED', res.data)
          resolve(res)
        })
        .catch((error) => {
          reject(error) // reject
        })
    })
  },

  async fetchProfileSavedSingle({ commit }, { id, size, page }) {
    return await new Promise((resolve, reject) => {
      this.$axios
        .get(`/author/saved/detail/${id}/?size=${size}&page=${page}`, {
          headers: { 'Accept-Language': this.$i18n.locale },
        })
        .then((res) => {
          commit('SET_PROFILE_SAVED_SINGLE', res.data.results)
          commit('SET_TOTAL_PAGES', res.data.total_pages)
          resolve(res)
        })
        .catch((error) => {
          reject(error) // reject
        })
    })
  },

  async fetchQR({ commit }) {
    return await new Promise((resolve, reject) => {
      this.$axios
        .get('auth/login/qr/', {
          headers: { 'Accept-Language': this.$i18n.locale },
        })
        .then((res) => {
          commit('SET_PROFILE_QR', res.data)
          resolve(res)
        })
        .catch((error) => {
          reject(error) // reject
        })
    })
  },

  async notificationList({ commit }) {
    return await new Promise((resolve, reject) => {
      this.$axios
        .get('author/profile/notification/list/', {
          headers: { 'Accept-Language': this.$i18n.locale },
        })
        .then((res) => {
          commit('SET_NOTIFICATION_LIST', res.data)
          resolve(res)
        })
        .catch((error) => {
          reject(error) // reject
        })
    })
  },

  async blogLists({ commit }) {
    return await new Promise((resolve, reject) => {
      this.$axios
        .get('author/profile/blog/company/list/', {
          headers: { 'Accept-Language': this.$i18n.locale },
        })
        .then((res) => {
          commit('SET_BLOG_LISTS', res.data)
          resolve(res)
        })
        .catch((error) => {
          reject(error) // reject
        })
    })
  },

  //   ================== BLOG
  async blogCategories({ commit }, data) {
    return await new Promise((resolve, reject) => {
      this.$axios
        .get('blog/category/', data, {
          headers: { 'Accept-Language': this.$i18n.locale },
        })
        .then((res) => {
          commit('SET_BLOG_CATEGORY', res.data)
          resolve(res)
        })
        .catch((error) => {
          reject(error) // reject
        })
    })
  },

  //   ================== COMPANY
  async createCompany({ commit }, data) {
    return await new Promise((resolve, reject) => {
      this.$axios
        .get('company/create', data, {
          headers: { 'Accept-Language': this.$i18n.locale },
        })
        .then((res) => {
          commit('SET_COMPANY', res.data)
          resolve(res)
        })
        .catch((error) => {
          reject(error) // reject
        })
    })
  },

  //   ================== BLACKLIST

  async blackList({ commit }) {
    return await new Promise((resolve, reject) => {
      this.$axios
        .get('author/profile/blacklist/', {
          headers: { 'Accept-Language': this.$i18n.locale },
        })
        .then((res) => {
          commit('SET_BLACK_LISTS', res.data.results)
          resolve(res)
        })
        .catch((error) => {
          reject(error) // reject
        })
    })
  },
}
