export const state = () => ({
  home: undefined,
  hasNext: true,
  comments: [],
  meta: {
    index: {
      title: 'Lenta.uz - hammasimas, eng kerklisi',
      img: '/meta/index.png',
      desc: "O'zbekiston va dunyo bo'ylab biznes, texnologiya, startaplar, iqtisodiyot, marketing bo'yicha filtrlangan maqolalar, xabarlar va tahlillar. Blogerlar va ekspertlar fikri, sharhlar hamda qiziqarli baxs-munozaralar taqdim etuvchi internet nashri. Biz sizga saralangan, tushunarli va foydali ma'lumotlarni 24/7 taqdim qilib boramiz.",
    },
    popular: {
      title: 'Lentadagi ommabop maqolalar',
      img: '/meta/popular.png',
      desc: 'Platformadagi reytingi baland bo’lgan ommabop maqolalarni epitsentriga xush kelibsiz.',
    },
    events: {
      title: 'Tadbirlar lentasi',
      img: '/meta/Event.png',
      desc: 'Respublikaning istalgan nuqtasidagi tadbirlar va voqealardan xabardor bo’lish uchun tadbirlar lentasini kuzating.',
    },
    vacancy: {
      title: 'Vakansiyalar lentasi',
      img: '/meta/vacancy.png',
      desc: 'Biznes, texnologiya, startaplar, iqtisodiyot, marketing va zamonaviy aktual sohalar bo’yicha filterlangan vakansiyalar.',
    },
    rating: {
      title: 'Lenta reytingi',
      img: '/meta/rating.png',
      desc: 'Platformadagi faol kontent chiqaruvchilar va muhokamalarda qatnashuvchilarning reytingi.',
    },
    weather: {
      title: 'Lentada ob-havo',
      img: '/meta/WEATHER.png',
      desc: 'Ob-havo ma’lumotlari chiroyli va kerakli joyda.',
    },
    company: {
      title: 'Kompaniyalardan xabalar',
      img: '/meta/COMPPA.png',
      desc: 'Mikrafonlar kompaniyalarga berildi. Platformada o’zlari haqida va qiziqarli maqolalar chiqarish uchun ayni muddao.',
    },
  },
})
export const mutations = {
  SET_HOME(state, payload) {
    state.home = payload
  },
  SET_NEXT(state, payload) {
    state.hasNext = payload !== null
  },
  SET_COMMENT(state, payload) {
    state.comments = payload
  },
}
export const actions = {
  async getHome({ commit }, { page, order, date = '' }) {
    return await new Promise((resolve, reject) => {
      this.$axios
        .get(
          `main/mobile/?page=${page}&ordering=${order}&published_date__gte=${date}`,
          {
            headers: { 'Accept-Language': this.$i18n.locale },
          }
        )
        .then((res) => {
          //   commit('SET_HOME', res.data.results);
          commit('SET_NEXT', res.data.next)
          resolve(res)
        })
        .catch((error) => {
          reject(error) // reject
        })
    })
  },
  async getComments({ commit }, { slug, latest }) {
    return await new Promise((resolve, reject) => {
      this.$axios
        .get(`/article/comments/${slug}?order_by=${latest}`, {
          headers: { 'Accept-Language': this.$i18n.locale },
        })
        .then((res) => {
          commit('SET_COMMENT', res.data)
          resolve(res)
        })
        .catch((error) => {
          reject(error) // reject
        })
    })
  },
}
