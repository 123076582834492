//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import SubBtn from '../../components/actions/SubBtn.vue'
import RecommendedPr from '../../components/others/preloader/RecommendedPr.vue'
import LoaderList from '@/components/actions/LoaderList.vue'

export default {
  components: {
    SubBtn,
    RecommendedPr,
    LoaderList,
  },
  props: {
    isVerified: { type: Boolean, default: true },
    img: { type: String, default: undefined },
    recommmendHeadClass: { type: String, default: 'mt-[36px]' },
  },
  data() {
    return {
      size: 15,
      page: 1,
      data: [],
    }
  },
  fetch() {},
  computed: {
    ...mapState({
      total_pages: (state) => state.subscribtion.total_pages,
      recommended: (state) => state.subscribtion.recommended,
    }),
  },
  watch: {
    recommended(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.data.push(...newValue)
      }
    },
  },
  methods: {
    loadMore(entries, observer, isIntersecting) {
      if (isIntersecting) {
        this.page += 1
        this.fetchData()
      }
    },
    async fetchData() {
      await this.$store
        .dispatch('subscribtion/fetchRecommendation', {
          size: this.size,
          page: this.page,
        })
        .then(() => {
          //   this.data.push(...res.data.results);
        })
        .catch(() => {})
    },
  },
}
