import Vue from 'vue'
import moment from 'moment'

const getLocale = function () {
  let locale = 'uz'
  if (this._i18n.locale === 'uz') {
    locale = 'uz_latn'
  } else if (this._i18n.locale === 'ru') {
    locale = 'ru_RU'
  } else if (this._i18n.locale === 'en') {
    locale = 'en_EN'
  }
  return locale
}

Vue.prototype.$m = moment
Vue.prototype.$mjs = function (date, type) {
  const locale = getLocale.call(this)
  const now = new Date().getTime()
  const formatedDate = new Date(date).getTime()

  // if date is less than 40 minutes
  if (now - formatedDate < 2400000) {
    return moment(date).locale(locale).fromNow()
  } else if (now - formatedDate < 3600000) {
    // if date is less than 1 hour
    return this.$t('minutes_ago_moment', [
      Math.floor((now - formatedDate) / 60000),
    ])
  } else if (now - formatedDate < 86400000) {
    // if date is less than 1 day
    return moment(date).locale(locale).calendar()
  } else {
    return moment(date).locale(locale).format('D-MMMM, HH:mm')
  }
}
Vue.prototype.$removeDateWords = function (str) {
  let words
  if (this._i18n.locale === 'uz') {
    words = ['Bir', 'necha', 'soat']
    for (let i = 0; i < words.length; i++) {
      if (str && str.includes(words[i])) {
        str = str.replace(words[i], '')
      }
    }
    if (str && str.includes('oldin')) {
      str = str.replace('oldin', 'avval')
    }
  }
  return str
}
Vue.prototype.$dateHourFormat = function (str) {
  let words
  if (this._i18n.locale === 'uz') {
    words = ['Bir', 'necha', 'soat']
    for (let i = 0; i < words.length; i++) {
      if (str && str.includes(words[i])) {
        str = str.replace(words[i], '')
      }
    }
    if (str && str.includes('oldin')) {
      str = str.replace('oldin', 'avval')
    }
  }
  return str
}
Vue.prototype.$removeDateWordsComment = function (str) {
  let words
  if (this._i18n.locale === 'uz') {
    words = ['necha', 'bir', 'soat', 'Bir']
    for (let i = 0; i < words.length; i++) {
      if (str && str.includes(words[i])) {
        str = str.replace(words[i], '')
      }
    }
    if (str && str.includes('oldin')) {
      str = str.replace('oldin', 'avval')
    }
  }
  return str
}
Vue.prototype.$moment = function (date, type) {
  const locale = getLocale.call(this)
  if (type) {
    return moment(date).locale(locale).format(type)
  }
}
// EVENTS TIMER
Vue.prototype.$eventTimer = function (date) {
  const now = new Date()
  const pareseDate = new Date(date)
  const { hours, minutes } = this.$getHoursAndMinutes(date)
  const locale = getLocale.call(this)
  if (hours > 24) {
    return moment(date).locale(locale).format('D-MMMM, HH:mm')
  } else if (hours < 24 && hours > 1) {
    return moment(date).locale(locale).calendar()
  } else if (hours < 1 && minutes > 1) {
    return this.$t('minutes', [Math.floor(minutes)])
  } else if ((now - pareseDate) / (1000 * 60 * 60) < 1) {
    return this.$t('onging')
  }
}

Vue.prototype.$getHoursAndMinutes = function (date) {
  const now = new Date()
  const event = new Date(date)
  const diff = event - now
  const hours = diff / 1000 / 60 / 60
  const minutes = (diff / 1000 / 60) % 60
  return { hours, minutes }
}
