export const state = () => ({
  tags: [],
  regions: [],
})
export const mutations = {
  SET_TAGS(state, tags) {
    state.tags = tags
  },
  SET_REGIONS(state, regions) {
    state.regions = regions
  },
}

export const actions = {
  async fetchTags({ commit }) {
    return await new Promise((resolve, reject) => {
      this.$axios
        .get('/tags/', {
          headers: { 'Accept-Language': this.$i18n.locale },
        })
        .then((res) => {
          commit('SET_TAGS', res.data)
          resolve(res)
        })
        .catch((error) => {
          reject(error) // reject
        })
    })
  },
  async fetchRegions({ commit }) {
    return await new Promise((resolve, reject) => {
      this.$axios
        .get('/regions/', {
          headers: { 'Accept-Language': this.$i18n.locale },
        })
        .then((res) => {
          commit('SET_REGIONS', res.data)
          resolve(res)
        })
        .catch((error) => {
          reject(error) // reject
        })
    })
  },
}

export const getters = {}
