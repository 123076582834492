//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    returnObject: {
      type: Boolean,
      default: false,
    },
    itemText: {
      type: String,
      default: 'text',
    },
    itemValue: {
      type: String,
      default: 'value',
    },
    customitem: {
      type: Boolean,
    },
    labelEnd: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    grid: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    classInput: {
      type: [Array, String],
      default: '',
    },
    classLabel: {
      type: String,
      default: '',
    },
    maxlength: {
      type: [String, Number],
      default: '100',
    },
    placeholder: {
      type: String,
      default: '',
    },
    position: {
      type: String,
      default: '',
    },
    textarea: {
      type: Boolean,
      default: false,
    },
    select: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: '',
    },
    mask: {
      type: String,
      default: '',
    },
    visitBlack: {
      type: Boolean,
      default: false,
    },
    toast: {
      type: Boolean,
      default: false,
    },
    // for select
    list: {
      type: Array,
      default: () => [
        { title: 'example', id: '1' },
        { title: 'example2', id: '2' },
      ],
    },
  },
  data() {
    return {
      phoneAlert: false,
    }
  },
  methods: {
    updateValue(value) {
      this.$emit('input', value)
    },
  },
}
