//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import SidebarUp from '../../components/SidebarUp.vue'
export default {
  components: {
    SidebarUp,
  },
  data() {
    return {
      filerData: [],
      staticMenuLists: [
        {
          id: 2,
          title: this.$t('the_latter'),
          icon: 'sidebar/songilari',
          badges: '99+',
          link: '/',
        },
        {
          id: 1,
          title: this.$t('popular'),
          icon: 'sidebar/ommabop',
          badges: '',
          link: '/popular',
        },
        {
          id: 3,
          title: this.$t('events'),
          icon: 'sidebar/tadbirlar',
          badges: '',
          link: '/events',
        },
        {
          id: 4,
          title: this.$t('vacancies'),
          icon: 'sidebar/vakansiyalar',
          badges: '',
          link: '/vacancy',
        },
        {
          id: 5,
          title: this.$t('rating'),
          icon: 'sidebar/reyting',
          badges: '',
          link: '/rating',
        },
        {
          id: 6,
          title: this.$t('weather'),
          icon: 'sidebar/ob-havo',
          badges: '',
          link: '/weather',
        },
        {
          id: 7,
          title: this.$t('companies'),
          icon: 'sidebar/kompaniyalar',
          badges: '',
          link: '/company',
        },
      ],
      menuList: [],
      list: false,
    }
  },
  async fetch() {
    await this.$store.dispatch('getSettings').catch(() => {})
    await this.$store.dispatch('sidebar/fetchCategories').catch(() => {})
    if (this.categories?.length > 5) {
      for (let i = 0; i < 5; i++) {
        this.filerData.push(this.categories[i])
      }
    } else {
      for (const item of this.categories) {
        this.filerData.push(item)
      }
    }
    if (process.client) {
      const moreShow = localStorage.getItem('more_sidebar')

      if (moreShow === 'true') {
        for (let i = 5; i < this.categories?.length; i++) {
          this.filerData.push(this.categories[i])
        }
      }
    }
  },
  computed: {
    ...mapState({
      categories: (state) => state.sidebar.categories,
      settings: (state) => state.settings,
    }),
    year() {
      return new Date().getFullYear()
    },
  },
  mounted() {
    window.addEventListener('click', (e) => {
      if (e.target.className === 'sidebar-close') {
        this.toggleMenu()
      }
    })
    // eslint-disable-next-line
    const moreShow = localStorage.getItem('more_sidebar')
  },
  methods: {
    async fetchRecommendModalDatas() {
      // default/modal => subscriberstab/recommend
      await this.$store
        .dispatch('subscribtion/fetchRecommendation', {
          size: 10,
          page: 1,
        })
        .catch(() => {})
    },
    toggleMenu() {
      this.$emit('toggleSidebar')
    },
    showMore() {
      if (this.categories > this.filerData) {
        for (let i = 5; i < this.categories?.length; i++) {
          this.filerData.push(this.categories[i])
        }
        if (process.client) {
          localStorage.setItem('more_sidebar', true)
        }
      } else {
        for (let i = 5; i < this.categories?.length; i++) {
          this.filerData.pop()
        }
        if (process.client) {
          localStorage.setItem('more_sidebar', false)
        }
      }
    },
  },
}
