import Vue from 'vue'
import YmapPlugin from 'vue-yandex-maps'

const settings = {
  apiKey: 'f98a1104-7762-4498-b15b-6c1ab87aceaf',
  lang: 'ru_RU',
  coordorder: 'latlong',
  enterprise: true,
  version: '2.1',
}

Vue.use(YmapPlugin, settings)
