//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import SubBtn from '../../components/actions/SubBtn.vue'
import MyFollowersPr from '../../components/others/preloader/MyFollowersPr.vue'

export default {
  components: {
    SubBtn,
    MyFollowersPr,
  },

  props: {
    categories: {
      type: Boolean,
      default: false,
    },
    searchFollowers: {
      type: String,
      default: '',
    },
    searchCompanies: {
      type: String,
      default: '',
    },
    moreClass: {
      type: String,
      default: 'mb-[36px]',
    },
  },

  data() {
    return {
      subsList: [],
      companies: [],
      allSubs: true,
      allCompany: true,
      limitSubs: 5,
      limitComp: 5,
    }
  },

  async fetch() {
    await this.$store
      .dispatch('subscribtion/fetchCompanyList', {
        search: '',
      })
      .then(() => {})
      .catch(() => {})
    await this.$store
      .dispatch('subscribtion/fetchSubsList', {
        search: '',
      })
      .then(() => {})
      .catch(() => {})
    this.subsList = this.lists
    this.companies = this.companys
  },

  computed: {
    ...mapState({
      lists: (state) => state.subscribtion.subsList,
      companys: (state) => state.subscribtion.companys,
    }),
  },
  watch: {
    async searchFollowers(newVal, oldVal) {
      if (newVal !== 0 && newVal !== oldVal) {
        await this.$store
          .dispatch('subscribtion/fetchSubsList', {
            search: newVal,
          })
          .then((res) => {
            this.subsList = this.lists
          })
          .catch(() => {})
      }
    },
    async searchCompanies(newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        await this.$store
          .dispatch('subscribtion/fetchCompanyList', {
            search: newVal,
          })
          .then((res) => {
            this.companies = this.companys
          })
          .catch(() => {})
      }
    },
    allSubs(newVal) {},
  },

  methods: {
    moreItems() {
      this.subsList = this.lists
      this.allSubs = false
      this.limitSubs = this.subsList.length
    },
    moreCompanies() {
      this.companies = this.companys
      this.allCompany = false
      this.limitComp = this.companys.length
    },
  },
}
