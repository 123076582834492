//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import Input from '../components/form/Input.vue'
import Modal from '@/components/form/Modal.vue'

export default {
  components: {
    Modal,
    Input,
  },
  data() {
    return {
      e1: 1,
      selected: '',
      reportMessage: '',
      report_type: undefined,
      pending: false,
    }
  },
  validations: {
    selected: {
      required,
    },
    reportMessage: {
      //   required
    },
  },

  computed: {
    ...mapState({
      reports: (state) => state.reports.reports,
      authorID: (state) => state.reports.authorID,
    }),
  },

  methods: {
    categories(id) {
      this.e1 = 2
      this.report_type = id
      this.selected = this.report_type
    },
    reportSent() {
      this.$v.$touch()

      if (this.$v.$anyError) {
        if (!this.$v.selected.required) {
          this.$toast.error(this.$t('reportcard.type'))
        }

        if (!this.$v.reportMessage.required) {
          this.$toast.error(this.$t('reportcard.message'))
        }
      }

      if (!this.$v.$anyError) {
        this.$axios
          .post('/report/report-create/', {
            author: this.authorID,
            report_type: this.report_type,
            description: this.reportMessage,
          })
          .then(() => {
            this.$modalHide('deleteTypeReport')
            this.e1 = 1
            this.$v.$reset()
            this.reportMessage = ''
            this.$toast.success(this.$t('reportcard.sent'))
          })
          .catch(() => {})
      }
    },
  },
}
