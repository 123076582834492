import Vue from 'vue'

Vue.prototype.$numberWithSpaces = function (num) {
  // return a number with spaces
  if (num !== null) return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}
// uchta nuqta
Vue.prototype.$truncate = function (source, size) {
  return source.length > size ? source.slice(0, size - 1) + '…' : source
}

Vue.prototype.$preventXXS = function (str) {
  if (!str) return ''
  // remove script tags and other dangerous tags
  return str
    .replace(/<script[^>]*>([\S\s]*?)<\/script>/gim, '')
    .replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim, '')
}
Vue.prototype.$hideEmail = function (email) {
  const censorWord = function (str) {
    return str[0] + '*'.repeat(str.length - 2) + str.slice(-1)
  }
  const arr = email.split('@')
  return censorWord(arr[0]) + '@' + censorWord(arr[1])
}
Vue.prototype.$addParams = function (key, val) {
  const queryParams = new URLSearchParams(window.location.search)
  queryParams.set(key, val)
  history.replaceState(null, null, '?' + queryParams.toString())
}

Vue.prototype.$modal = function (id) {
  document.querySelector(`#${id}`).classList.add('_active')
  document.documentElement.classList.add('_lock')
  document.querySelector(`#${id}`).__vue__._data.modalIsOpen = true
}
Vue.prototype.$modalHide = function (id) {
  document.querySelector(`#${id}`).classList.remove('_active')
  document.documentElement.classList.remove('_lock')
  document.querySelector(`#${id}`).__vue__._data.modalIsOpen = false
}

Vue.prototype.$getError = function (form, type) {
  /*
  form : Vuelidate Object
  return : First error string.
  */
  function isObject(val) {
    return val instanceof Object
  }
  // if (form.$model && isObject(form.$model)) {
  //   const formKeys = Object.keys(form.$model);
  //   let params = form.$flattenParams();
  //   console.log(form, formKeys, params);
  //   formKeys.forEach((element, index) => {
  //     params.forEach((item) => {
  //       if (form[element] && form[element].$anyError && !form[element][item.name]) {
  //         return Vue.$toast.error(`${element}-${item.name}`);
  //       }
  //     });
  //   });
  // }

  if (form.$model && isObject(form.$model)) {
    const keys = Object.keys(form.$model)
    for (let i = 0; i < keys.length; i++) {
      if (form[keys[i]] && form[keys[i]].$anyError) {
        if (form[keys[i]].$model) {
          return Vue.prototype.$getError(form[keys[i]])
        }
        const params = form[keys[i]].$flattenParams()
        for (let k = 0; k < params.length; k++) {
          if (!form[keys[i]][params[k].name]) {
            if (type) {
              return Vue.$toast.error(
                this.$t(type + '.' + keys[i] + '_' + params[k].name)
              )
            } else {
              return Vue.$toast.error(this.$t(keys[i] + '_' + params[k]?.name))
            }
          }
        }
      } else {
        const params = form.$flattenParams()
        for (let k = 0; k < params.length; k++) {
          if (!form[params[k].name]) {
            // return Vue.$toast.error(params[k].name);
          }
        }
      }
    }
  }
}

Vue.prototype.$validationError = function (data) {
  if (data.detail) {
    // return Vue.$toast.error('iajsjndjasndkj s ad');\
    return Vue.$toast.error(data.detail)
  }
  const keys = Object.keys(data)
  for (const key of keys) {
    if (Array.isArray(data[key])) {
      Vue.prototype.$validationError(data[key])
    } else if (typeof key === 'string' || key instanceof String) {
      Vue.$toast.error(data[key])
    }
  }
}
Vue.prototype.$handleChatbarResponsive = function ({ hide, toggle }) {
  this.$store.commit('setChatbarHidden', hide)
  this.$store.commit('setChatbarToggle', toggle)
}
Vue.prototype.$handleBeforeAuth = function (showingLinks) {
  if (!showingLinks) {
    this.showingLinks = true
  } else {
    this.showingLinks = false
  }
}
