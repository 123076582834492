//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
export default {
  data() {
    return {
      e1: 1,
      notificationsList: [],
      switches: {
        n_commented_post: false,
        n_comment_reaction: false,
        n_new_subscription: false,
        n_event: false,
        n_vacancy: false,
        n_post: false,
        n_updates: false,
      },
    }
  },
  async fetch() {
    if (this.$auth.loggedIo) {
      await this.$store
        .dispatch('getNotifications')
        .then((res) => {
          this.notificationsList = res.results
        })
        .catch(() => {})
      await this.$store.dispatch('profile/notificationList').catch(() => {})

      this.switches.n_commented_post = this.notes.n_commented_post
      this.switches.n_comment_reaction = this.notes.n_comment_reaction
      this.switches.n_new_subscription = this.notes.n_new_subscription
      this.switches.n_event = this.notes.n_event
      this.switches.n_vacancy = this.notes.n_vacancy
      this.switches.n_post = this.notes.n_post
      this.switches.n_updates = this.notes.n_updates
    }
  },
  computed: {
    ...mapState({
      notes: (state) => state.profile.notes_list,
    }),
  },
  watch: {
    async 'switches.n_commented_post'(n, o) {
      if (o) {
        await this.$axios.patch('author/profile/notification/update/', {
          n_commented_post: this.switches.n_commented_post,
        })
      }
    },
    async 'switches.n_comment_reaction'(n, o) {
      if (o) {
        await this.$axios.patch('author/profile/notification/update/', {
          n_comment_reaction: this.switches.n_comment_reaction,
        })
      }
    },
    async 'switches.n_new_subscription'(n, o) {
      if (o) {
        await this.$axios.patch('author/profile/notification/update/', {
          n_new_subscription: this.switches.n_new_subscription,
        })
      }
    },
    async 'switches.n_event'(n, o) {
      if (o) {
        await this.$axios.patch('author/profile/notification/update/', {
          n_event: this.switches.n_event,
        })
      }
    },
    async 'switches.n_vacancy'(n, o) {
      if (o) {
        await this.$axios.patch('author/profile/notification/update/', {
          n_vacancy: this.switches.n_vacancy,
        })
      }
    },
    async 'switches.n_post'(n, o) {
      if (o) {
        await this.$axios.patch('author/profile/notification/update/', {
          n_post: this.switches.n_post,
        })
      }
    },
    async 'switches.n_updates'(n, o) {
      if (o) {
        await this.$axios.patch('author/profile/notification/update/', {
          n_updates: this.switches.n_updates,
        })
      }
    },
  },
  methods: {
    handleStep() {
      if (this.$auth.loggedIn) {
        this.e1 === 1 ? (this.e1 = 2) : (this.e1 = 1)
      }
    },
  },
}
