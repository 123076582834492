import { postAction, getAction, updateAction } from '~/assets/scripts/actions'

export const state = () => ({
  chatbarHidden: 1100,
  chatbarToggle: 1500,
  sidebarHidden: 1540,
  replyComment: '',
  listWishlist: [],
  articles: [],
  articlesCount: 0,
  post: undefined,
  staticPage: undefined,
  articleId: '',
  settings: undefined,
})
export const mutations = {
  // the window size when chatbar is hidden
  setChatbarHidden(state, value) {
    state.chatbarHidden = value
  },
  setChatbarToggle(state, value) {
    state.chatbarToggle = value
  },
  setReplyCommentId(state, value) {
    state.replyComment = value
  },
  setWishlist(state, value) {
    state.listWishlist = value
  },
  SET_WS_ARTICLE(state, value) {
    state.articles = value
  },
  SET_WS_ARTICLE_COUNT(state, value) {
    state.articlesCount = value
  },
  SET_ARTICLE_SINGLE(state, value) {
    state.post = value
  },
  SET_STATIC_PAGE(state, value) {
    state.staticPage = value
  },
  SET_ARTICLE_ID(state, id) {
    state.articleId = id
  },
  SET_SETTINGS(state, settings) {
    state.settings = settings
  },
}
export const actions = {
  mainSearch: getAction('/main/search/'),
  headerSearch: getAction('/main/search/functional_suggest/'),
  headerAds: getAction('/advertisement/body/'),
  bodyAds: getAction('/advertisement/header/'),
  createEvent: postAction('event/create/'),
  createBlog: postAction('blog/create/'),
  updateEvent: updateAction('blog/update/{slug}/'),
  detailEvent: getAction('blog/detail/{slug}/'),
  createVacancy: postAction('vacancy/create/'),
  updateVacancy: updateAction('vacancy/update/{slug}/'),
  listTags: getAction('tags'),
  listTagsSingle: getAction('{type}/related/tag/{slug}/'),
  listAuthors: getAction('author/select'),
  listCategoryEvent: getAction('event/category'),
  listEventPlace: getAction('event/location/list/'),
  createCompany: postAction('company/create/'),
  updateCompany: updateAction('company/update/{slug}/'),
  detailCompany: getAction('company/detail/{slug}/'),
  companyCategories: getAction('company/category/'),
  regions: getAction('regions/'),
  districts: getAction('districts/'),
  createTag: postAction('tags/create/'),
  articleWishlistCreate: postAction('author/saved/article/add/'),
  articleWishlistDelete: postAction('author/saved/article/remove/'),
  eventWishlistCreate: postAction('author/saved/event/add/'),
  eventWishlistDelete: postAction('author/saved/event/remove/'),
  vacancyWishlistCreate: postAction('author/saved/vacancy/add/'),
  vacancyWishlistDelete: postAction('author/saved/vacancy/remove/'),
  addWishlist: postAction('author/saved/'),
  getVacancySettings: getAction('vacancy/settings/'),
  getEventSettings: getAction('event/settings/'),
  getNotifications: getAction('notifications/'),
  getVacancyTarif: getAction('payment/vacancy/tariffs/'),
  //   getArticleComments: getAction('ws/last-comments/?limit=10'),
  async getArticleComments({ commit }) {
    return await new Promise((resolve, reject) => {
      this.$axios
        .get('ws/last-comments/?limit=10', {
          headers: { 'Accept-Language': this.$i18n.locale },
        })
        .then((res) => {
          resolve(res.data)
        })
        .catch((error) => {
          reject(error) // reject
        })
    })
  },
  async wishlist({ commit }) {
    return await new Promise((resolve, reject) => {
      this.$axios
        .get('author/saved/', {
          headers: { 'Accept-Language': this.$i18n.locale },
        })
        .then((res) => {
          commit('setWishlist', res.data)
          resolve(res.data)
        })
        .catch((error) => {
          reject(error) // reject
        })
    })
  },
  async getSettings({ commit }) {
    return await new Promise((resolve, reject) => {
      this.$axios
        .get('settings/', {
          headers: { 'Accept-Language': this.$i18n.locale },
        })
        .then((res) => {
          commit('SET_SETTINGS', res.data)
          resolve(res)
        })
        .catch((error) => {
          reject(error) // reject
        })
    })
  },
  async getWSArtcile({ commit }, limit) {
    return await new Promise((resolve, reject) => {
      this.$axios
        .get(`ws/last-articles/?limit=${limit}`, {
          headers: { 'Accept-Language': this.$i18n.locale },
        })
        .then((res) => {
          commit('SET_WS_ARTICLE', res.data.results)
          commit('SET_WS_ARTICLE_COUNT', res.data.count)
          resolve(res)
        })
        .catch((error) => {
          reject(error) // reject
        })
    })
  },
  async postArticle({ commit }, { data }) {
    return await new Promise((resolve, reject) => {
      this.$axios
        .post('/article/crud/add/', data, {
          headers: { 'Accept-Language': this.$i18n.locale },
        })
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error) // reject
        })
    })
  },
  async updateArticle({ commit }, { data, id }) {
    return await new Promise((resolve, reject) => {
      this.$axios
        .patch(`/article/crud/${id}/`, data, {
          headers: { 'Accept-Language': this.$i18n.locale },
        })
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error) // reject
        })
    })
  },
  async getArticleSingle({ commit }, slug) {
    return await new Promise((resolve, reject) => {
      this.$axios
        .get(`article/detail/${slug}/`, {
          headers: { 'Accept-Language': this.$i18n.locale },
        })
        .then((res) => {
          commit('SET_ARTICLE_SINGLE', res.data)
          commit('SET_ARTICLE_ID', res.data.id)
          resolve(res)
        })
        .catch((error) => {
          reject(error) // reject
        })
    })
  },
  async getStaticPage({ commit }, slug) {
    return await new Promise((resolve, reject) => {
      this.$axios
        .get(`/static-page/${slug}/`, {
          headers: { 'Accept-Language': this.$i18n.locale },
        })
        .then((res) => {
          commit('SET_STATIC_PAGE', res.data)
          resolve(res)
        })
        .catch((error) => {
          reject(error) // reject
        })
    })
  },
}
