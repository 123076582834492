export const state = () => ({
  author: undefined,
  posts: [],
  events: [],
  vacancies: [],
  subs: [],
  comments: [],
  total_pages: 1,
})
export const mutations = {
  SET_AUTHOR(state, author) {
    state.author = author
  },
  SET_AUTHOR_POST(state, post) {
    state.posts = post
  },
  SET_AUTHER_EVENTS(state, events) {
    state.events = events
  },
  SET_AUTHER_VACANCY(state, vacancy) {
    state.vacancies = vacancy
  },
  SET_AUTHER_COMMENTS(state, comments) {
    state.comments = comments
  },
  SET_AUTHER_SUBS(state, sub) {
    state.subs = sub
  },
  SET_TOTAL_PAGES(state, total) {
    state.total_pages = total
  },
}
export const actions = {
  async fetchAuthor({ commit }, username) {
    return await new Promise((resolve, reject) => {
      this.$axios
        .get(`author/${username}/`, {
          headers: { 'Accept-Language': this.$i18n.locale },
        })
        .then((res) => {
          commit('SET_AUTHOR', res.data)
          resolve(res)
        })
        .catch((error) => {
          reject(error) // reject
        })
    })
  },
  async fetchAuthorPost(
    { commit },
    { username, size, page, sort = '', latest }
  ) {
    return await new Promise((resolve, reject) => {
      this.$axios
        .get(`author/${username}/articles`, {
          params: {
            page,
            size,
            published_date__gte: latest ? '' : sort,
            ordering: latest ? '-published_date' : '',
          },
          headers: { 'Accept-Language': this.$i18n.locale },
        })
        .then((res) => {
          commit('SET_AUTHOR_POST', res.data.results)
          commit('SET_TOTAL_PAGES', res.data.total_pages)
          resolve(res)
        })
        .catch((error) => {
          reject(error) // reject
        })
    })
  },
  async fetchAuthorEvents({ commit }, { username, size, page }) {
    return await new Promise((resolve, reject) => {
      this.$axios
        .get(`author/${username}/event?size=${size}&page=${page}`, {
          headers: { 'Accept-Language': this.$i18n.locale },
        })
        .then((res) => {
          commit('SET_AUTHER_EVENTS', res.data.results)
          commit('SET_TOTAL_PAGES', res.data.total_pages)
          resolve(res)
        })
        .catch((error) => {
          reject(error) // reject
        })
    })
  },
  async fetchVacancies({ commit }, { username, size, page }) {
    return await new Promise((resolve, reject) => {
      this.$axios
        .get(`author/${username}/vacancy?size=${size}&page=${page}`, {
          headers: { 'Accept-Language': this.$i18n.locale },
        })
        .then((res) => {
          commit('SET_AUTHER_VACANCY', res.data.results)
          commit('SET_TOTAL_PAGES', res.data.total_pages)
          resolve(res)
        })
        .catch((error) => {
          reject(error) // reject
        })
    })
  },
  async fetchAuthorComments({ commit }, { username, size, page }) {
    return await new Promise((resolve, reject) => {
      this.$axios
        .get(`author/${username}/comments?size=${size}&page=${page}`, {
          headers: { 'Accept-Language': this.$i18n.locale },
        })
        .then((res) => {
          commit('SET_AUTHER_COMMENTS', res.data.results)
          commit('SET_TOTAL_PAGES', res.data.total_pages)
          resolve(res)
        })
        .catch((error) => {
          reject(error) // reject
        })
    })
  },
  async fetchAuthorFollowers({ commit }, { username, size, page }) {
    return await new Promise((resolve, reject) => {
      this.$axios
        .get(`author/${username}/subscribers/?size=${size}&page=${page}`, {
          headers: { 'Accept-Language': this.$i18n.locale },
        })
        .then((res) => {
          commit('SET_AUTHER_SUBS', res.data.results)
          commit('SET_TOTAL_PAGES', res.data.total_pages)
          // console.log(res.data.results);
          resolve(res)
        })
        .catch((error) => {
          reject(error) // reject
        })
    })
  },
}
