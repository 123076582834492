//
//
//
//

export default {
  props: {
    date: {
      type: String,
      default: '',
    },
  },
  methods: {
    handleMinAgo(time) {
      return this.removeWords(this.$m(time).locale(this.getLocale()).fromNow())
    },
    // handleMinAgo (time) {
    //   return this.removeWords(this.$m(time).locale(this.getLocale()).fromNow());
    // },
    getLocale() {
      let locale = 'uz'
      if (this._i18n.locale === 'uz') {
        locale = 'uz_latn'
      } else if (this._i18n.locale === 'ru') {
        locale = 'ru_RU'
      } else if (this._i18n.locale === 'en') {
        locale = 'en_EN'
      }
      return locale
    },
    removeWords(str) {
      const words = ['Bir', 'necha', 'oldin', 'Soniya', 'soniya']

      for (let i = 0; i < words.length; i++) {
        if (str && str.includes(words[i])) {
          str = str.replace(words[i], '')
        }
      }
      if (str.includes('daqiqa')) {
        str = str.replace('daqiqa', 'daq')
      }
      return str
    },
  },
}
