export const state = () => ({
  subsList: [],
  companys: [],
  recommended: [],
  total_pages: 0,
})
export const mutations = {
  SET_SUBSLIST(state, subs) {
    state.subsList = subs
  },
  SET_COMPANYLIST(state, companys) {
    state.companys = companys
  },
  SET_RECOMMENDATION(state, recommended) {
    state.recommended = recommended
  },
  SET_TOTAL(state, total) {
    state.total_pages = total
  },
}

export const actions = {
  async fetchSubsList({ commit }, { search }) {
    return await new Promise((resolve, reject) => {
      this.$axios
        .get(`/author/subscribed/list/?search=${search}`, {
          headers: { 'Accept-Language': this.$i18n.locale },
        })
        .then((res) => {
          commit('SET_SUBSLIST', res.data.results)
          resolve(res)
        })
        .catch((error) => {
          reject(error) // reject
        })
    })
  },
  async fetchCompanyList({ commit }, { search }) {
    return await new Promise((resolve, reject) => {
      this.$axios
        .get(`/company/list/?search=${search}`, {
          headers: { 'Accept-Language': this.$i18n.locale },
        })
        .then((res) => {
          commit('SET_COMPANYLIST', res.data.results)
          resolve(res)
        })
        .catch((error) => {
          reject(error) // reject
        })
    })
  },
  async fetchRecommendation({ commit }, { size, page }) {
    return await new Promise((resolve, reject) => {
      this.$axios
        .get(`/author/profile/recommendation/list?size=${size}&page=${page}`, {
          headers: { 'Accept-Language': this.$i18n.locale },
        })
        .then((res) => {
          commit('SET_RECOMMENDATION', res.data.results)
          commit('SET_TOTAL', res.data.total_pages)
          resolve(res)
        })
        .catch((error) => {
          reject(error) // reject
        })
    })
  },
}
