//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import HeaderNotifications from '../notice/HeaderNotifications.vue'
import Modal from '@/components/form/Modal.vue'
export default {
  components: {
    Modal,
    HeaderNotifications,
  },
  auth: 'guest',
  data() {
    return {
      searchList: [],
      select: null,
      inMobile: false,
      findAllText: false,
      search: '',
      activeMenu: false,
      rotateIcon: false,
      profileArrow: false,
      searchInput: false,
    }
  },
  async fetch() {
    if (this.$auth.loggedIn) {
      await this.$store.dispatch('wishlist').catch(() => {})
      await this.$store
        .dispatch('getNotifications')
        .then((res) => {
          this.notificationsList = res.results
        })
        .catch(() => {})
      // await this.$store.dispatch('reports/fetchReports').catch(() => {});
    }
  },
  computed: {
    availableLocales() {
      return this.$i18n.locales.filter((i) => i.code !== this.$i18n.locale)
    },
  },
  watch: {
    activeMenu(e) {
      if (e) {
        document.querySelector('body').classList.add('_lock')
      } else {
        document.querySelector('body').classList.remove('_lock')
      }
    },
    async search(item) {
      this.findAllText = true
      if (item) {
        await this.$store
          .dispatch('headerSearch', {
            params: {
              title_suggest_match: item,
            },
          })
          .then((response) => {
            if (
              response.title_suggest_match.length &&
              response.title_suggest_match[0].options
            ) {
              this.searchList = response.title_suggest_match[0].options
            }
          })
          .catch(() => {})
      }
    },
    $route(to, from) {
      setTimeout(() => {
        if (to.path === '/search/') {
          this.$refs.searchInput2.focus()
        }
      }, 1000)
    },
  },
  mounted() {
    this.menuMobile()
    this.hideElement('rotateIcon', '.activeRotate')
    this.hideElement('profileArrow', '.rotateProfileArrow')
    this.animateLogoAday()
  },
  methods: {
    handlePush() {
      //   window.$OneSignal.push(['addListenerForNotificationOpened', (data) => {
      //   }
      //   ]);
    },
    animateLogoAday() {
      if (process.client) {
        const now = new Date()
        const then = localStorage.getItem('then')
        const nowMs = now.getTime()
        if (now - then < 86400000) {
          return true
        }
        document.querySelector('.header__logo').classList.add('animate')
        setTimeout(() => {
          document.querySelector('.header__logo').classList.remove('animate')
        }, 10000)
        localStorage.setItem('then', nowMs)
      }
    },
    async logOut() {
      await this.$auth.logout()
      this.$modalHide('logOut')

      location.reload()
    },
    menuMobile() {
      const burger = document.querySelector('.icon-menu')
      const menuBody = document.querySelector('.menu__body')
      if (burger && menuBody) {
        burger.addEventListener('click', () => {
          burger.classList.toggle('_active')
          menuBody.classList.toggle('_active')
        })
        const linksMenu = document.querySelectorAll('.menu__link')
        if (linksMenu) {
          for (const item of linksMenu) {
            item.addEventListener('click', () => {
              burger.classList.remove('_active')
              menuBody.classList.remove('_active')
            })
          }
        }
      }
    },
    hideElement(item, className) {
      document.addEventListener('mousedown', (event) => {
        if (!event.target.closest(className)) {
          this[item] = false
        }
      })
    },
    rotatePlusIcon() {
      this.rotateIcon = !this.rotateIcon
    },
    profileArrowToggle() {
      this.profileArrow = !this.profileArrow
    },
    navigateLentam() {
      this.$forceUpdate()
      this.$router.push({
        path: this.$route.path,
        query: {
          lenta: true,
        },
      })
    },
    navigateLentam1() {
      this.$forceUpdate()
      this.$router.push({
        path: this.$route.path,
        query: {
          lenta: false,
        },
      })
    },
    redirectToSearch() {
      if (this.$route.path.includes('/search')) {
        this.$router.push({ query: { search: this.search } })
        this.search = ''
      } else {
        this.$router.push({ path: '/search', query: { search: this.search } })
        this.search = ''
      }
      this.findAllText = false
    },

    // checkRoute() {
    //   if (this.$router.currentRoute.fullPath === '/') {
    //     console.log(this.$router)
    //   } else {
    //     console.log('other')
    //   }
    // },
  },
}
