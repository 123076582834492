//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ChatbarMinAgo from './ChatbarMinAgo.vue'
export default {
  // props: ['showChat'],
  components: {
    ChatbarMinAgo,
  },
  data() {
    return {
      activeLive: true,
      random: Math.random(),
      counterInterval: null,
      chat: false,
      connection: null,
      comments: [],
      webData: [],
    }
  },
  async fetch() {
    await this.$store
      .dispatch('getArticleComments')
      .then((res) => {
        this.comments = res.results
      })
      .catch(() => {})
  },
  watch: {
    activeLive(val) {
      localStorage.setItem('isChat', val)
    },
  },
  created() {
    if (process.client) {
      try {
        this.connection = new WebSocket(this.$config.WS_URL)

        this.connection.onmessage = function (event) {
          const data = JSON.parse(event.data)
          if (data.type === 'comments') {
            if (data.message.method === 'created') {
              this.comments.unshift(data.message)
            } else if (data.message.method === 'delete') {
              this.comments = this.comments.filter(
                (comment) => comment.id !== data.message.id
              )
            } else if (data.message.method === 'update') {
              this.comments = this.comments.map((comment) => {
                if (comment.id === data.message.id) {
                  return data.message
                }
                return comment
              })
            }
          }
        }.bind(this)

        this.connection.onerror = function () {}
        this.connection.onopen = function (event) {}
      } catch {}
    }
  },
  mounted() {
    this.counterInterval = setInterval(() => {
      this.random = Math.random()
    }, 30000)

    localStorage.getItem('isChat') === 'true'
      ? (this.activeLive = true)
      : (this.activeLive = false)
  },
  beforeDestroy() {
    clearInterval(this.counterInterval)
  },
}
