//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    recommendSub: {
      type: Boolean,
      default: false,
    },
    categoriesSub: {
      type: Boolean,
      default: false,
    },
    subed: {
      type: Boolean,
    },
    user: {
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      subscribe: true,
      followToCategory: false,
    }
  },

  mounted() {
    this.followToCategory = this.subed
    this.subscribe = this.subed
  },

  methods: {
    async subscribeToUser() {
      if (this.$auth.user) {
        this.subscribe = !this.subscribe

        if (this.subscribe) {
          await this.$axios
            .post('/author/subscription/subscribe/', {
              author: this.user,
            })
            .then(() => {})
            .catch(() => {})
        } else {
          await this.$axios
            .post('/author/subscription/unsubscribe/', {
              author: this.user,
            })
            .then(() => {})
            .catch(() => {})
        }
      } else {
        this.$toast.warning("Obuna bo'lish uchun ro'yxatdan o'ting")
      }
    },
    async subscribeCategories() {
      if (this.$auth.user) {
        this.followToCategory = !this.followToCategory

        if (!this.followToCategory) {
          await this.$axios
            .post('/author/subscription/unsubscribe/', {
              author: this.user,
            })
            .then(() => {})
            .catch(() => {})
        } else {
          await this.$axios
            .post('/author/subscription/subscribe/', {
              author: this.user,
            })
            .then(() => {})
            .catch(() => {})
        }
      } else {
        this.$toast.warning("Obuna bo'lish uchun ro'yxatdan o'ting")
      }
    },
  },
}
