//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import Sidebar from '../components/layout/Sidebar.vue'
import Chatbar from '../components/layout/Chatbar.vue'
import PushNotifications from '../components/notice/PushNotifications.vue'
import MobileMenu from '../components/layout/MobileMenu.vue'
import SubscribersTab from '@/components/form/SubscribersTab.vue'
import Modal from '@/components/form/Modal.vue'
import Header from '~/components/layout/Header.vue'
import ReportCard from '~/components/ReportCard.vue'
export default {
  components: {
    Header,
    SubscribersTab,
    Modal,
    ReportCard,
    Sidebar,
    Chatbar,
    PushNotifications,
    MobileMenu,
    // BannerTop
    // Report
  },
  auth: 'guest',
  scrollToTop: true,
  data() {
    return {
      inactive: true,
      windowWidth: 1300,
      windowWidthSidebar: 0,
      sidebarShow: false,
      hideChatbar: false,
      position: false,
    }
  },
  computed: {
    ...mapState({
      chatbarHidden: (state) => state.chatbarHidden,
      chatbarToggle: (state) => state.chatbarToggle,
      sidebarHidden: (state) => state.sidebarHidden,
    }),
  },
  watch: {
    sidebarShow(showSidebar) {
      // document.querySelector('html').classList.remove('_lock');
      const htmlElement = document.querySelector('html')
      if (this.windowWidth < 800) {
        if (this.sidebarShow) {
          htmlElement.classList.add('_lock')
        } else {
          htmlElement.classList.remove('_lock')
        }
      }
    },
  },
  beforeMount() {
    this.windowWidth = window.innerWidth
    // if (this.windowWidth < 800) {
    //   this.sidebarShow = true;
    // }
    window.addEventListener('resize', this.onResize)
    this.onResize()

    this.windowWidthSidebar = this.windowWidth
    window.addEventListener('resize', this.onSidebarResize)
    this.onSidebarResize()
  },
  destroyed() {
    window.removeEventListener('resize', this.onResize)
    window.removeEventListener('resize', this.onSidebarResize)
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth
      this.chatbarHiding(this.sidebarShow)
      //   this.windowWidth < 800 ? (this.sidebarShow = true) : (this.sidebarShow = false);
      this.mobileSidebar()
    },
    onSidebarResize() {
      this.windowWidthSidebar = window.innerWidth
    },
    mobileSidebar() {
      // this.sidebarShow && this.windowWidth < 800
      //   ? document.querySelector('html').classList.add('_lock')
      //   : document.querySelector('html').classList.remove('_lock');
    },
    toggleSidebar() {
      this.mobileSidebar()
      this.sidebarShow = !this.sidebarShow
      this.chatbarHiding(this.sidebarShow)
    },
    chatbarHiding(sidebar) {
      this.hideChatbar = false
      if (this.windowWidth < this.chatbarToggle && !sidebar) {
        this.hideChatbar = true
      } else {
        this.hideChatbar = false
      }
    },
  },
}
