export const state = () => ({
  vacancy: [],
  vacancyHelpers: {
    requirements: {},
    tasks: {},
    conditions: {},
  },
  vacancies: [],
  vacanciesSingle: undefined,
  authorSelect: [],
  categoryList: [],
  tariffTypes: [],
  total_count: 1,
  autherVacancies: {
    items: [],
    total: 0,
    page: 0,
  },
  reletedVacancies: {
    items: [],
    total: 0,
    page: 0,
  },
})
export const mutations = {
  setVacancy(state, value) {
    state.vacancy = value
  },
  setVacancyHelpers(state, value) {
    state.vacancyHelpers = value
  },
  SET_VACANCIES(state, vacancies) {
    state.vacancies = vacancies
  },
  SET_TOTAL_COUNT(state, total) {
    state.total_count = total
  },
  SET_VACANCIES_SINGLE(state, vacanciesSingle) {
    state.vacanciesSingle = vacanciesSingle
  },
  SET_AUTHOR_SELECT(state, authorSelect) {
    state.authorSelect = authorSelect
  },
  SET_CATEGORY_LIST(state, categoryList) {
    state.categoryList = categoryList
  },
  SET_TARIFF_TYPES(state, tariffTypes) {
    state.tariffTypes = tariffTypes
  },
  SET_AUTHER_VACANCIES(state, vacancies) {
    state.autherVacancies.items = vacancies.results
    state.autherVacancies.total = vacancies.total
    state.autherVacancies.page = vacancies.current_page
  },
  PUSH_AUTHER_VACANCIES(state, vacancies) {
    state.autherVacancies.items.push(...vacancies.results)
    state.autherVacancies.page = vacancies.current_page
  },
  SET_RELETED_VACANCIES(state, vacancies) {
    state.reletedVacancies.items = vacancies.results
    state.reletedVacancies.total = vacancies.total
    state.reletedVacancies.page = vacancies.current_page
  },
  PUSH_RELETED_VACANCIES(state, vacancies) {
    state.reletedVacancies.items.push(...vacancies.results)
    state.reletedVacancies.page = vacancies.current_page
  },
}

export const actions = {
  async fetchVacancies({ commit }, filters) {
    return await new Promise((resolve, reject) => {
      const params = {
        title__contains: filters.multiSearch || undefined,
        status: filters.status || undefined,
        region__terms: filters.region ? filters.region.join('__') : undefined,
        schedule__terms: filters.employment
          ? filters.employment.join('__')
          : undefined,
        is_remote__terms: filters.isRemote
          ? filters.isRemote.join('__')
          : undefined,
        category__terms: filters.category
          ? filters.category.join('__')
          : undefined,
        page_size: filters.size || 10,
      }
      this.$axios
        .get('vacancy/', {
          params,
          headers: {
            'Accept-Language': this.$i18n.locale,
          },
        })
        .then((res) => {
          commit('SET_VACANCIES', res.data.results)
          commit('SET_TOTAL_COUNT', res.data.count)
          resolve(res)
        })
        .catch((error) => {
          reject(error) // reject
        })
    })
  },
  async fetchVacanciesSingle({ commit }, { slug }) {
    return await new Promise((resolve, reject) => {
      this.$axios
        .get(`vacancy/detail/${slug}/`, {
          headers: {
            'Accept-Language': this.$i18n.locale,
          },
        })
        .then((res) => {
          commit('SET_VACANCIES_SINGLE', res.data)
          resolve(res)
        })
        .catch((error) => {
          reject(error) // reject
        })
    })
  },
  async fetchAuthorSelect({ commit }) {
    return await new Promise((resolve, reject) => {
      this.$axios
        .get('author/select/', {
          headers: {
            'Accept-Language': this.$i18n.locale,
          },
        })
        .then((res) => {
          commit('SET_AUTHOR_SELECT', res.data)
          resolve(res)
        })
        .catch((error) => {
          reject(error) // reject
        })
    })
  },
  async fetchCategoryList({ commit }, search = '') {
    return await new Promise((resolve, reject) => {
      this.$axios
        .get('vacancy/category/list/', {
          params: {
            search,
          },
          headers: {
            'Accept-Language': this.$i18n.locale,
          },
        })
        .then((res) => {
          commit('SET_CATEGORY_LIST', res.data)
          resolve(res)
        })
        .catch((error) => {
          reject(error) // reject
        })
    })
  },
  async fetchAuthorVacancies({ commit }, { slug, page = 1, size = 3 }) {
    return await new Promise((resolve, reject) => {
      this.$axios
        .get(`/vacancy/author/related/${slug}/`, {
          headers: {
            'Accept-language': this.$i18n.locale,
          },
          params: {
            page,
            size,
          },
        })
        .then((res) => {
          page > 1
            ? commit('PUSH_AUTHER_VACANCIES', res.data)
            : commit('SET_AUTHER_VACANCIES', res.data)
          resolve(res)
        })
        .catch((error) => {
          reject(error) // reject
        })
    })
  },
  async fetchReletedVacancies({ commit }, { slug, page = 1, size = 3 }) {
    return await new Promise((resolve, reject) => {
      this.$axios
        .get(`/vacancy/related/${slug}/`, {
          headers: {
            'Accept-language': this.$i18n.locale,
          },
          params: {
            page,
            size,
          },
        })
        .then((res) => {
          page > 1
            ? commit('PUSH_RELETED_VACANCIES', res.data)
            : commit('SET_RELETED_VACANCIES', res.data)
          resolve(res)
        })
        .catch((error) => {
          reject(error) // reject
        })
    })
  },
  async fetchVacancyTariffTypes({ commit }) {
    return await new Promise((resolve, reject) => {
      this.$axios
        .get('payment/vacancy/tariffs/', {
          headers: {
            'Accept-language': this.$i18n.locale,
          },
        })
        .then((res) => {
          commit('SET_TARIFF_TYPES', res.data)
          resolve(res)
        })
        .catch((error) => {
          reject(error) // reject
        })
    })
  },
}

export const getters = {
  autherVacancies: (s) => s.autherVacancies,
  reletedVacancies: (s) => s.reletedVacancies,
  categoryList: (s) => s.categoryList,
}
