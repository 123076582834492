var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form__group",class:{ '!grid grid-cols-12 items-center': _vm.grid }},[(_vm.label)?_c('div',{staticClass:"flex items-center justify-between gap-[16px] w-full",class:[
      { 'col-span-4 d:col-span-full': _vm.grid },
      { '!justify-end': _vm.labelEnd } ]},[_c('label',{staticClass:"form__label",class:[_vm.classLabel, { 'col-span-4 d:col-span-full': _vm.grid }],attrs:{"for":_vm.id || _vm._uid}},[_vm._v(_vm._s(_vm.label))]),_vm._v(" "),_vm._t("extra")],2):_vm._e(),_vm._v(" "),_c('div',{staticClass:"!relative w-full",class:[
      { 'col-span-8 d:col-span-full': _vm.grid },
      { 'cursor-not-allowed': _vm.disabled } ]},[(_vm.position && _vm.position.includes('right'))?_c('div',{staticClass:"form__addright absolute right-0 top-1/2 translate-y-[-50%] w-auto h-auto z-10 mr-3"},[_vm._t("default")],2):_vm._e(),_vm._v(" "),(_vm.position && _vm.position.includes('left'))?_c('div',{staticClass:"form__addleft absolute ml-3 left-0 top-1/2 translate-y-[-50%] w-auto h-auto z-10 mr-3"},[_vm._t("left")],2):_vm._e(),_vm._v(" "),(_vm.textarea)?_c('textarea',{staticClass:"form__txt",class:_vm.classInput,attrs:{"id":_vm.id || _vm._uid,"type":"text","disabled":_vm.disabled ? true : false,"maxlength":_vm.maxlength,"placeholder":_vm.placeholder},domProps:{"value":_vm.value},on:{"change":function($event){return _vm.updateValue($event.target.value)},"focus":function($event){return _vm.$emit('focus', $event)},"blur":function($event){return _vm.$emit('blur', $event)}}}):(_vm.select)?_c('v-select',{staticClass:"form__select w-full",class:_vm.classInput,attrs:{"items":_vm.list,"item-text":_vm.itemText,"item-value":_vm.itemValue,"return-object":_vm.returnObject === '' || _vm.returnObject == true ? true : false,"hide-details":true,"disabled":_vm.disabled,"solo":"","menu-props":{
        bottom: true,
        offsetY: true,
        nudgeBottom: 10,
        closeOnClick: true,
        contentClass: 'hashtag__options',
      },"label":_vm.placeholder,"value":_vm.value},on:{"input":function($event){return _vm.updateValue($event)}},scopedSlots:_vm._u([(_vm.customitem)?{key:"item",fn:function(){return [_vm._t("item")]},proxy:true}:null],null,true)}):[(_vm.mask)?[(_vm.toast)?_c('div',{staticClass:"form__message absolute left-0 bg-[#333] text-white border border border-solid border-[#606060] rounded-lg py-[5px] px-[10px] text-[11px] opacity-0 invisibile w-full max-w-[375px] text-center transition duration-300",class:{ _active: _vm.phoneAlert }},[_vm._v("\n          "+_vm._s(_vm.$t('for_security'))+"\n        ")]):_vm._e(),_vm._v(" "),_c('input',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.mask),expression:"mask"}],staticClass:"form__inp",class:_vm.classInput,attrs:{"id":_vm.id || _vm._uid,"type":"text","maxlength":_vm.maxlength,"placeholder":_vm.placeholder},domProps:{"value":_vm.value},on:{"change":function($event){return _vm.updateValue($event.target.value)},"blur":function($event){_vm.phoneAlert = false
            _vm.$emit('blur', $event)},"focus":function($event){_vm.phoneAlert = true
            _vm.$emit('focus', $event)},"input":function($event){_vm.phoneAlert = false}}})]:[_c('input',{staticClass:"form__inp",class:_vm.classInput,attrs:{"id":_vm.id || _vm._uid,"type":"text","disabled":_vm.disabled ? true : false,"maxlength":_vm.maxlength,"placeholder":_vm.placeholder},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.updateValue($event.target.value)},"focus":function($event){return _vm.$emit('focus', $event)},"blur":function($event){return _vm.$emit('blur', $event)}}})]]],2)])}
var staticRenderFns = []

export { render, staticRenderFns }