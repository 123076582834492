export const state = () => ({
  blogsList: [],
  companyList: [],
  usersList: [],
})

export const mutations = {
  SET_BLOGS_LIST(state, blogsList) {
    state.blogsList = blogsList
  },
  SET_COMPANY_LIST(state, companyList) {
    state.companyList = companyList
  },
  SET_USERS_LIST(state, usersList) {
    state.usersList = usersList
  },
}

export const actions = {
  async fetchBlogsList({ commit }, { filter, size }) {
    return await new Promise((resolve, reject) => {
      this.$axios
        .get(`author/rate/blogs/?filter=${filter}&size=${size}`, {
          headers: { 'Accept-language': this.$i18n.locale },
        })
        .then((res) => {
          commit('SET_BLOGS_LIST', res.data.results)
          // console.log(res, 'ress');
          resolve(res)
        })
        .catch((error) => {
          reject(error) // reject
        })
    })
  },
  async fetchCompanyList({ commit }, { filter, size }) {
    return await new Promise((resolve, reject) => {
      this.$axios
        .get(`author/rate/companies/?filter=${filter}&size=${size}`, {
          headers: { 'Accept-language': this.$i18n.locale },
        })
        .then((res) => {
          commit('SET_COMPANY_LIST', res.data.results)
          resolve(res)
        })
        .catch((error) => {
          reject(error) // reject
        })
    })
  },
  async fetchUsersList({ commit }, { filter, size }) {
    return await new Promise((resolve, reject) => {
      this.$axios
        .get(`author/rate/users/?filter=${filter}&size=${size}`, {
          headers: { 'Accept-language': this.$i18n.locale },
        })
        .then((res) => {
          commit('SET_USERS_LIST', res.data.results)
          resolve(res)
        })
        .catch((error) => {
          reject(error) // reject
        })
    })
  },
}
