export const state = () => ({
  categories: [],
})
export const mutations = {
  SET_CATEGORIES(state, categories) {
    state.categories = categories
  },
}

export const actions = {
  async fetchCategories({ commit }) {
    return await new Promise((resolve, reject) => {
      this.$axios
        .get('article/categories/', {
          headers: { 'Accept-Language': this.$i18n.locale },
        })
        .then((res) => {
          commit('SET_CATEGORIES', res.data)
          resolve(res)
        })
        .catch((error) => {
          reject(error) // reject
        })
    })
  },
}
