//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LottieAnimation from 'lottie-web-vue' // import lottie-web-vue
import Modal from '@/components/form/Modal'

export default {
  components: {
    Modal,
    LottieAnimation,
  },
  data() {
    return {
      random: Math.random(),
      settings: {},
    }
  },
  async fetch() {
    await this.$store.dispatch('getSettings').then(() => {
      this.settings = this.$store.state.settings
    })
  },
  watch: {
    $route(newValue, oldValue) {
      this.random = Math.random()
    },
  },
}
