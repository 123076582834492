export const state = () => ({
  reports: [],
  authorID: undefined,
  commentId: undefined,
  reportsComment: [],
})

export const mutations = {
  SET_REPORTS(state, reports) {
    state.reports = reports
  },
  SET_AUTHOR_ID(state, authorID) {
    state.authorID = authorID
  },
  SET_COMMENT_ID(state, payload) {
    state.commentId = payload
  },
  SET_REPORTS_COMMENT(state, payload) {
    state.reportsComment = payload
  },
}

export const actions = {
  async fetchReports({ commit }) {
    return await new Promise((resolve, reject) => {
      this.$axios
        .get('report/', {
          headers: {
            'Accept-language': this.$i18n.locale,
          },
        })
        .then((res) => {
          commit('SET_REPORTS', res.data)
          // console.log(res.data);

          resolve(res)
        })
        .catch((error) => {
          reject(error) // reject
        })
    })
  },
  async fetchReportsComment({ commit }) {
    return await new Promise((resolve, reject) => {
      this.$axios
        .get('article/comments/report-type/', {
          headers: {
            'Accept-language': this.$i18n.locale,
          },
        })
        .then((res) => {
          commit('SET_REPORTS_COMMENT', res.data)
          resolve(res)
        })
        .catch((error) => {
          reject(error) // reject
        })
    })
  },
}
