export const state = () => ({
  region: [],
  districts: [],
})
export const mutations = {
  SET_REGION(state, region) {
    state.region = region
  },
  SET_DISTRICT(state, district) {
    state.districts = district
  },
}

export const actions = {
  async fetchRegion({ commit }, search = '') {
    return await new Promise((resolve, reject) => {
      this.$axios
        .get('regions/', {
          params: {
            search,
          },
          headers: { 'Accept-Language': this.$i18n.locale },
        })
        .then((res) => {
          commit('SET_REGION', res.data)
          resolve(res)
        })
        .catch((error) => {
          reject(error) // reject
        })
    })
  },
  async fetchDistrict({ commit }, id) {
    return await new Promise((resolve, reject) => {
      this.$axios
        .get(`districts/?region=${id}`, {
          headers: { 'Accept-Language': this.$i18n.locale },
        })
        .then((res) => {
          // console.log(res.data);
          commit('SET_DISTRICT', res.data)
          resolve(res)
        })
        .catch((error) => {
          reject(error) // reject
        })
    })
  },
}
